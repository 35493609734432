import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        this.handleResize = this.checkWindowSize.bind(this);
        this.checkWindowSize();
        window.addEventListener("resize", this.handleResize);
    }

    checkWindowSize() {
        if (window.innerWidth < 1070) {
            this.enableMobileMenu();
        }
    }

    enableMobileMenu() {
        const burger = document.querySelector(".header_burger");

        if (!burger || burger._menuInitialized) return;
        burger.addEventListener("click", function (e) {
            e.target.querySelector(".menu").classList.toggle("active");
            document.querySelector(".header_menu").classList.toggle("open");
            document.querySelector("header").classList.toggle("menuOpen");
        });
        burger._menuInitialized = true;

        const getChildrenElements = document.querySelectorAll(".getChildren");
        getChildrenElements.forEach((item) => {
            if (item._toggleInitialized) return;
            item.addEventListener("click", function () {
                item.classList.toggle("active");
            });
            item._toggleInitialized = true;
        });
    }
}
