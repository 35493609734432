import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";

Swiper.use(Navigation);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        let container = document.querySelector('.chiffreCles');
        if(container) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        container.querySelectorAll(".chiffre").forEach(function (item, i) {
                            let value = item.getAttribute('data-chiffre');
                            let x = 0;
                            item.innerHTML = "0";
                            if (i === 1) {
                                setInterval(function () {
                                    if (x < value) {
                                        x += 4;
                                        if (x > value) {
                                            x = value
                                        }
                                        item.innerHTML = x;
                                    }
                                }, 50)
                            } else {
                                setInterval(function () {
                                    if (x < value) {
                                        x += 1;
                                        item.innerHTML = x;
                                    }
                                }, 100)
                            }
                        });
                    }
                })
            })
            observer.observe(container);
        }
    }
}