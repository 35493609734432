import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";

Swiper.use(Navigation);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        let tabReveal = document.querySelectorAll(".reveal");
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(function (entry){
                if(entry.isIntersecting){
                    entry.target.classList.add("revealOn");
                }
            })
        }, {
            rootMargin: '-30%'
        })
        tabReveal.forEach(function(item,i){
            observer.observe(item);
        })

    }
}
