import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";

Swiper.use(Navigation);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if(document.querySelector('.etapeConstruction')) {
            if(screen.width < 768) {
                const swiper = new Swiper('#swiperEtape', {
                    slidesPerView: 1,
                    loop: true,
                    spaceBetween: 50,

                    navigation: {
                        nextEl: '#etapeConstructionNext',
                        prevEl: '#etapeConstructionPrev',
                    },

                });
            }
            else{
                const swiper = new Swiper('#swiperEtape', {
                    slidesPerView: 3,
                    loop: true,
                    spaceBetween: 50,

                    navigation: {
                        nextEl: '#etapeConstructionNext',
                        prevEl: '#etapeConstructionPrev',
                    },
                });
            }
        }
    }
}
