import {Controller} from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import {Navigation, Grid} from "swiper/modules";

Swiper.use(Navigation);
Swiper.use(Grid);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if (document.querySelector('.blog')) {
            let listSmartcontent = document.querySelectorAll(".blog_content_left_indiv_list");
            if (listSmartcontent.length !== 1) {
                listSmartcontent.forEach(function (items, i) {
                    if (screen.width < 768) {
                        const swiper = new Swiper('#swiperBlog' + i, {
                            slidesPerView: 1,
                            loop: true,
                            spaceBetween: 50,

                            navigation: {
                                nextEl: '#blogNext' + i,
                                prevEl: '#blogPrev' + i,
                            },
                        });
                    } else {
                        const swiper = new Swiper('#swiperBlog' + i, {
                            slidesPerView: 2,
                            loop: true,
                            spaceBetween: 50,

                            navigation: {
                                nextEl: '#blogNext' + i,
                                prevEl: '#blogPrev' + i,
                            },
                        });
                    }
                })
            }
            else{
                listSmartcontent.forEach(function (items, i) {
                    if (screen.width < 768) {
                        const swiper = new Swiper('#swiperBlogOne', {
                            slidesPerView: 1,
                            loop: true,
                            spaceBetween: 50,

                            navigation: {
                                nextEl: '#blogOneNext',
                                prevEl: '#blogOnePrev',
                            },
                        });
                    } else {
                        const swiper = new Swiper('#swiperBlogOne', {
                            slidesPerView: 2,
                            loop: true,
                            spaceBetween: 50,

                            grid: {
                                fill: "row",
                                rows: 2,
                            },

                            navigation: {
                                nextEl: '#blogOneNext',
                                prevEl: '#blogOnePrev',
                            },
                        });
                    }
                })
            }
        }
    }
}
