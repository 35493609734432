import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Autoplay, Navigation} from "swiper/modules";

Swiper.use(Navigation);
Swiper.use(Autoplay);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if (document.querySelector(".labels")) {
            if (screen.width > 768) {
                const swiper = new Swiper('#labelsSwiper', {
                    slidesPerView: 6,
                    loop: true,
                    loopedSlidesLimit: false,
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false,
                    },
                    speed: 1000,
                });
            }
            else{
                const swiper = new Swiper('#labelsSwiper', {
                    slidesPerView: 2.3,
                    mousewheelControl: true,
                    initialSlide: 0,
                    // loop: true,
                });
            }
        }
    }
}
