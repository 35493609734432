import {Controller} from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Autoplay, Navigation} from "swiper/modules";

Swiper.use(Navigation);
Swiper.use(Autoplay);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {

        //animation chapeau

        if (document.querySelector(".heroBanner_chapeau")) {
            //split word
            if (document.querySelector(".heroBanner_chapeau p")) {
                let tabWord = document.querySelector(".heroBanner_chapeau p").innerHTML.split(" ");
                document.querySelector(".heroBanner_chapeau p").innerHTML = ""
                tabWord.forEach((e) => {
                    document.querySelector(".heroBanner_chapeau p").innerHTML += '<div> ' + e + '\xa0</div>';
                })
            } else {
                let tabWord = document.querySelector(".heroBanner_chapeau h2").innerHTML.split(" ");
                document.querySelector(".heroBanner_chapeau h2").innerHTML = ""
                tabWord.forEach((e) => {
                    document.querySelector(".heroBanner_chapeau h2").innerHTML += '<div> ' + e + '\xa0</div>';
                })
            }
            // animation
            if (screen.width < 768) {
                gsap.registerPlugin(ScrollTrigger);
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.heroBanner_chapeau',
                        start: '-350px',
                        end: '+=30%',
                        scrub: 1,
                        pin: false,
                        // markers: true
                    },
                });
                tl.to(".heroBanner_chapeau div", {stagger: 1, color: '#000'}, 1);
            } else {
                gsap.registerPlugin(ScrollTrigger);
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.heroBanner_chapeau',
                        start: '-600px',
                        end: '-150px',
                        scrub: 1,
                        pin: false,
                        // markers: true
                    },
                });
                tl.to(".heroBanner_chapeau div", {stagger: 1, color: '#000'}, 1);
            }
        }
    }
}
