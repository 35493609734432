import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";

Swiper.use(Navigation);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if(document.querySelector('.realisations')) {
            let arrayList = document.querySelectorAll(".realisations_container_left_list li");
            let arraySwiper = document.querySelectorAll(".realisations_container_right_list_swiper");
            let arrayBottomInfo = document.querySelectorAll(".sliderBottom");
            let arrayActive = 0;
            if(document.querySelector(".realisations").getAttribute("data-categorie") != null && arrayList.length > 1){
                switch (document.querySelector(".realisations").getAttribute("data-categorie")){
                    case 'Particuliers':
                        arrayActive = 0;
                        arrayList[0].classList.add("active");
                        arraySwiper[0].classList.add("active");
                        arrayBottomInfo[0].classList.add("active");
                        break;
                    case 'Professionnels':
                        arrayActive = 1;
                        arrayList[1].classList.add("active");
                        arraySwiper[1].classList.add("active");
                        arrayBottomInfo[1].classList.add("active");
                        break;
                    case 'Travaux':
                        arrayActive = 2;
                        arrayList[2].classList.add("active");
                        arraySwiper[2].classList.add("active");
                        arrayBottomInfo[2].classList.add("active");
                        break;
                }
            }
            else{
                arrayActive = 0;
                arrayList[0].classList.add("active");
                arraySwiper[0].classList.add("active");
                arrayBottomInfo[0].classList.add("active");
            }
            arrayList.forEach(function (item,i){
                //click list
                item.addEventListener("click",function (e){
                    arrayList[arrayActive].classList.remove("active");
                    arraySwiper[arrayActive].classList.remove("active");
                    arrayBottomInfo[arrayActive].classList.remove("active");
                    item.classList.add("active");
                    arraySwiper[i].classList.add("active");
                    arrayBottomInfo[i].classList.add("active");
                    arrayActive = i;
                })
                if(screen.width < 768) {

                    let currentBottomInfo = document.querySelectorAll(".sliderBottom")[i];
                    let currentSwiper = document.querySelectorAll(".realisations_container_right_list_swiper")[i];
                    currentBottomInfo.querySelector(".sliderBottom_left a").innerHTML = currentSwiper.querySelectorAll(".swiper-slide")[0].getAttribute('data-name');
                    currentBottomInfo.querySelector(".sliderBottom_left a").setAttribute('href',currentSwiper.querySelectorAll(".swiper-slide")[0].getAttribute('data-url'));
                    currentBottomInfo.querySelector(".sliderBottom_right a").setAttribute('href',currentSwiper.querySelectorAll(".swiper-slide")[0].getAttribute('data-url'));
                    const swiper = new Swiper('#realSwiper' + i, {
                        slidesPerView: 1,
                        loop: false,
                        spaceBetween: 15,

                        navigation: {
                            nextEl: '#realNext' + i,
                            prevEl: '#realPrev' + i,
                        },

                        on: {
                            slideChange(){
                                let currentIndex = this.realIndex;
                                let currentSlide = this.slides[this.realIndex];
                                currentBottomInfo.querySelector(".sliderBottom_left a").innerHTML = currentSlide.getAttribute('data-name');
                                currentBottomInfo.querySelector(".sliderBottom_left a").setAttribute('href',currentSlide.getAttribute('data-url'));
                                currentBottomInfo.querySelector(".sliderBottom_right a").setAttribute('href',currentSlide.getAttribute('data-url'));
                            },
                        },

                    });
                }
                else{
                    let currentBottomInfo = document.querySelectorAll(".sliderBottom")[i];
                    let currentSwiper = document.querySelectorAll(".realisations_container_right_list_swiper")[i];
                    currentBottomInfo.querySelector(".sliderBottom_left a").innerHTML = currentSwiper.querySelectorAll(".swiper-slide")[0].getAttribute('data-name');
                    currentBottomInfo.querySelector(".sliderBottom_left a").setAttribute('href',currentSwiper.querySelectorAll(".swiper-slide")[0].getAttribute('data-url'));
                    currentBottomInfo.querySelector(".sliderBottom_right a").setAttribute('href',currentSwiper.querySelectorAll(".swiper-slide")[0].getAttribute('data-url'));
                    const swiper = new Swiper('#realSwiper' + i, {
                        slidesPerView: 1,
                        loop: false,

                        navigation: {
                            nextEl: '#realNext' + i,
                            prevEl: '#realPrev' + i,
                        },
                        on: {
                            slideChange(){
                                let currentIndex = this.realIndex;
                                let currentSlide = this.slides[this.realIndex];
                                currentBottomInfo.querySelector(".sliderBottom_left a").innerHTML = currentSlide.getAttribute('data-name');
                                currentBottomInfo.querySelector(".sliderBottom_left a").setAttribute('href',currentSlide.getAttribute('data-url'));
                                currentBottomInfo.querySelector(".sliderBottom_right a").setAttribute('href',currentSlide.getAttribute('data-url'));
                            },
                        },

                    });
                }

            })
        }
    }
}
