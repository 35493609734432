import { Controller } from '@hotwired/stimulus';
import {Swiper} from "swiper";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if(document.querySelector(".expertsArtisans")){
            const swiper = new Swiper('#expertsArtisansSwiper', {
                slidesPerView: 2,
                loop: false,
                spaceBetween: 15,

                navigation: {
                    nextEl: '#expertsArtisansNext',
                    prevEl: '#expertsArtisansPrev',
                },

            });
        }
    }
}
