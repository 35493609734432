import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Autoplay, Navigation} from "swiper/modules";

Swiper.use(Navigation);
Swiper.use(Autoplay);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if(document.querySelector('.homepage_heroBanner')) {
            const swiper = new Swiper('#swiperHPHeroBanner', {
                slidesPerView: 1,
                loop: true,
                speed: 1000,

                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },

                navigation: {
                    nextEl: '#hpHeroBannerNext',
                    prevEl: '#hpHeroBannerPrev',
                },

            });
        }


        //animation chapeau

        if(document.querySelector(".homepage_heroBanner_title_chapeau")){
            if(document.querySelector(".chapeauHomepage")){
                //split word
                if(document.querySelector(".homepage_heroBanner_title_chapeau p")) {
                    let tabWord = document.querySelector(".homepage_heroBanner_title_chapeau p").innerHTML.split(" ");
                    document.querySelector(".homepage_heroBanner_title_chapeau p").innerHTML = ""
                    tabWord.forEach((e) => {
                        document.querySelector(".homepage_heroBanner_title_chapeau p").innerHTML += '<div> ' + e + '\xa0</div>';
                    })
                }
                else{
                    let tabWord = document.querySelector(".homepage_heroBanner_title_chapeau h2").innerHTML.split(" ");
                    document.querySelector(".homepage_heroBanner_title_chapeau h2").innerHTML = ""
                    tabWord.forEach((e) => {
                        document.querySelector(".homepage_heroBanner_title_chapeau h2").innerHTML += '<div> ' + e + '\xa0</div>';
                    })
                }
                // animation
                if(screen.width < 768) {
                    gsap.registerPlugin(ScrollTrigger);
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: '.homepage_heroBanner_title_chapeau',
                            start: '-350px',
                            end: '+=30%',
                            scrub: 1,
                            pin: false,
                            // markers: true
                        },
                    });
                    tl.to(".homepage_heroBanner_title_chapeau div", {stagger:1, color:'#000'}, 1);
                }
                else{
                    gsap.registerPlugin(ScrollTrigger);
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: '.homepage_heroBanner_title_chapeau',
                            start: '-300%',
                            end: '+=30%',
                            scrub: 1,
                            pin: false,
                            // markers: true
                        },
                    });
                    tl.to(".homepage_heroBanner_title_chapeau div", {stagger:1, color:'#000'}, 1);
                }
            }
        }
    }
}
