import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if(screen.width > 768) {
            let arrayItem = document.querySelectorAll(".textImage_list_item");
            arrayItem.forEach(function (item, i) {
                let height = item.querySelector(".textImage_list_item_right").clientHeight;
                let imageDiv = item.querySelector(".textImage_list_item_left");
                imageDiv.style.height = height + "px";
            })
        }
    }
}
