import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";

Swiper.use(Navigation);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if (document.querySelector(".savoirFaire")) {

            let previousActive = 0;
            let arrayList = document.querySelectorAll(".savoirFaire_container_list li");
            let arrayListText = document.querySelectorAll(".savoirFaire_container_text_indiv");
            arrayList[0].classList.add("active");
            arrayListText[0].classList.add("active");

            arrayList.forEach(function (items,i){
                items.addEventListener("click",function (){
                    arrayList[previousActive].classList.remove("active");
                    arrayListText[previousActive].classList.remove("active");
                    previousActive = i;
                    items.classList.add('active');
                    arrayListText[i].classList.add("active");
                })
            })
        }
    }
}
