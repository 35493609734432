import { Controller } from '@hotwired/stimulus';
//swiperJS
import {Swiper} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from "swiper/modules";

Swiper.use(Navigation);


/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    connect() {
        if(document.querySelector(".pattern")){
            if(screen.width > 768) {
                const tl3 = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.contenuSimple',
                        start: '-=150%',
                        end: '+=100%',
                        scrub: 3,
                        pin: false,
                        // marker: true,
                    },
                });
                tl3.to(".contenuSimple .pattern", {y: "0%"}, 0);
            }
        }

    }
}
